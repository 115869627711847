<template>
  <div>
    <!-- search input -->
    <div class="d-flex justify-content-between mb-2">
      <div>
        <slot name="header-button-left" />
      </div>
      <div>
        <slot name="header-button" />
      </div>
    </div>

    <!-- table -->
    <vue-good-table
      style-class="table b-table"
      mode="remote"
      :columns="columns"
      :rows="rows"
      :sort-options="{ enabled: false }"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: false,
      }"
      :pagination-options="{
        enabled: true,
      }"
      @on-sort-change="onSortChange"
    >
      <template slot="emptystate">
        <div class="vgt-center-align vgt-text-disabled">
          Опять ничего нет :(
        </div>
      </template>

      <template
        v-for="(x, slotName) in $scopedSlots"
        v-slot:[slotName]="context"
      >
        <slot
          :name="slotName"
          v-bind="context"
        />
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div class="d-flex justify-content-between flex-wrap">

          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Отображено {{ settings.from }} — {{ settings.to }}, всего {{ settings.total }}
            </span>
          </div>

          <!-- pagination -->
          <div>
            <b-pagination
              :value="settings.currentPage"
              :total-rows="settings.total"
              :per-page="settings.perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <prism
      v-if="devLogs"
      class="rounded mt-1"
    >
      {{ log.join('\n') }}
    </prism>
  </div>
</template>

<script>
import { BPagination } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'

export default {
  components: {
    VueGoodTable,
    Prism,
    BPagination,
  },
  props: {
    columns: {
      required: true,
      type: Array,
    },
    rows: {
      required: true,
      type: Array,
    },
    settings: {
      required: true,
      type: Object,
    },
    devLogs: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      log: [],
      pageLength: 1,
      currentPage: 1,
      dir: false,
      pages: ['5', '10', '50', '100'],
      searchTerm: '',
    }
  },
  methods: {
    handleSearch(searching) {
      this.log.push(`The user searched for: ${searching}`)
    },
    handleChangePage(page) {
      this.$emit('updateFilter', {
        page,
      })
      this.log.push(`The user changed the page to: ${page}`)
    },
    handlePageChange(active) {
      this.log.push(`the user change page:  ${active}`)
    },
    onSortChange(params) {
      this.log.push(`the user ordered:  ${params[0].type}`)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
