<template>
  <b-card>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <div v-else>
      <div class="d-flex justify-content-end">
        <b-dropdown
          id="dropdown-form"
          ref="dropdown"
          class="mr-1"
          right
          no-flip
          variant="secondary"
          text="Фильтр"
        >
          <b-dropdown-form class="py-1">
            <b-form-group
              label="ФИО"
              label-for="dropdown-form-full_name"
              @submit.prevent.stop
            >
              <b-form-input
                id="dropdown-form-full_name"
                v-model="filters.full_name"
                size="sm"
              />
            </b-form-group>

            <b-form-group
              label="Статус заказа"
              label-for="status"
              @submit.prevent.stop
            >
              <b-form-select
                id="status"
                v-model="filters.status_order"
                size="sm"
                :options="[{id: null, name: ' '}, {id: 'all', name: 'Все статусы'}, {id: 1, name: 'Новый заказ'},{id: 3, name: 'Завершено'},{id: 4, name: 'Резерв'}]"
                :text-field="'name'"
                :value-field="'id'"
              />
            </b-form-group>

            <b-form-group
              label="Выполняющий работы"
              label-for="executor"
              @submit.prevent.stop
            >
              <b-form-select
                id="executor"
                v-model="filters.executor"
                size="sm"
                :options="allUsers"
                :text-field="'full_name'"
                :value-field="'id'"
              />
            </b-form-group>

            <b-form-group
              label="VIN"
              label-for="dropdown-form-vin"
              @submit.prevent.stop
            >
              <b-form-input
                id="dropdown-form-vin"
                v-model="filters.vin"
                size="sm"
              />
            </b-form-group>

            <b-form-group
              label="Гос. номер"
              label-for="dropdown-form-gos_number"
              @submit.prevent.stop
            >
              <b-form-input
                id="dropdown-form-gos_number"
                v-model="filters.gos_number"
                size="sm"
              />
            </b-form-group>

            <b-form-group
              label="Кол-во на странице"
              label-for="dropdown-form-per-page"
              @submit.prevent.stop
            >
              <b-form-select
                id="dropdown-form-per-page"
                v-model="filters.perPage"
                size="sm"
                :options="[10, 20, 50, 100, 500]"
              />
            </b-form-group>

            <b-button-group>
              <b-button
                variant="primary"
                size="sm"
                @click="doApplyFilters"
              >
                Применить
              </b-button>
              <b-button
                variant="outline-secondary"
                size="sm"
                @click="doResetFilters"
              >
                Сбросить
              </b-button>
            </b-button-group>
          </b-dropdown-form>
        </b-dropdown>
        <router-link :to="{name: 'orders-create'}">
          <b-button variant="primary">
            Создать
          </b-button>
        </router-link>
      </div>

      <data-table
        :columns="columns"
        :rows.sync="rows"
        :settings="settings"
        :dev-logs="false"
        @updateFilter="doUpdateFilter"
      >
        <template
          slot="table-column"
          slot-scope="props"
        >
          <div
            v-if="props.column.label === 'Действия'"
            class="text-center"
            style="min-width: 120px"
          >
            Действия
          </div>
          <div
            v-else
            class="text-center"
          >
            {{ props.column.label }}
          </div>
        </template>
        <template v-slot:table-row="item">
          <div
            v-if="item.column.field === 'v_status'"
            class="text-center"
          >
            <b-badge
              v-if="item.row.status_order === 1"
              variant="info"
            >
              Новый
            </b-badge>
            <b-badge
              v-else-if="item.row.status_order === 2"
              variant="warning"
            >
              В работе
            </b-badge>
            <b-badge
              v-else-if="item.row.status_order === 3"
              variant="success"
            >
              Завершенный
            </b-badge>
            <b-badge v-else-if="item.row.status_order === 4">
              Резерв
            </b-badge>
            <b-badge
              v-else
              variant="danger"
            >
              Неизвестно
            </b-badge>
          </div>
          <div v-else-if="item.column.field === 'vin'">
            <b-link
              v-if="displayVIN(item.row.auto_info_vin) !== '—'"
              @click="selectedVIN(item.row.auto_info_vin)"
            >
              {{ displayVIN(item.row.auto_info_vin) }}
            </b-link>
            <div v-else>
              {{ displayVIN(item.row.auto_info_vin) }}
            </div>
          </div>
          <div
            v-else-if="item.column.field === 'action'"
            class="text-center"
          >
            <router-link :to="{name: 'orders-duplicate', params: {id: item.row.id}}">
              <b-button
                variant="flat-primary"
                class="btn-icon"
              >
                <feather-icon
                  icon="CopyIcon"
                  class="text-primary"
                />
              </b-button>
            </router-link>

            <router-link :to="{name: 'orders-update', params: {id: item.row.id}}">
              <b-button
                variant="flat-warning"
                class="btn-icon"
              >
                <feather-icon
                  icon="Edit3Icon"
                  class="text-warning"
                />
              </b-button>
            </router-link>

            <b-button
              variant="flat-danger"
              class="btn-icon"
              @click="objectDelete(item.row.id)"
            >
              <feather-icon
                icon="Trash2Icon"
                class="text-danger"
              />
            </b-button>
          </div>
          <div
            v-else
            class="text-center"
          >
            {{ item.formattedRow[item.column.field] }}
          </div>
        </template>
      </data-table>
    </div>
  </b-card>
</template>
<script>
import DataTable from '@/components/DataTable.vue'
import {
  BLink,
  BFormGroup, BCard, BDropdown, BDropdownForm, BButton, BButtonGroup, BFormSelect, BSpinner, BFormInput, BBadge,
} from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import _ from 'lodash'
import { rowsObjectConverter, settingsObjectConverter } from '@/helpers/dataTableHelper'
import dataTableMixin from '@/mixins/dataTableMixin'

export default {
  name: 'CategoriesList',
  components: {
    BLink,
    BBadge,
    BCard,
    BFormGroup,
    BDropdown,
    BDropdownForm,
    BButton,
    BButtonGroup,
    BFormSelect,
    BFormInput,
    BSpinner,
    DataTable,
  },
  mixins: [
    dataTableMixin,
  ],
  data() {
    return {
      allUsers: null,
      columns: [
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'Создано',
          field: row => this.formateDate(row.created_at),
        },
        {
          label: 'ФИО',
          field: row => `${row.full_name ?? '—'}`,
        },
        {
          label: 'Марка',
          field: row => `${row.auto_info_mark ?? '—'} ${row.auto_info_model ?? ''}`,
        },
        {
          label: 'VIN',
          field: 'vin',
        },
        {
          label: 'Статус',
          field: 'v_status',
        },
        {
          label: 'Действия',
          field: 'action',
        },
      ],
    }
  },
  beforeMount() {
    // Собираем пачку промисов для предварительной загрузки
    const promises = []

    promises.push(() => this.doLoadUsers())
    promises.push(() => this.loadAllUsers())

    // Пушим первую загрузку данных
    this.loadingPromise(promises)

    // Пушим промисы фильтров, чтоб вызывать методы
    this.filterPromises.push(() => this.doLoadUsers())
  },
  methods: {
    selectedVIN(vin) {
      this.filters.vin = vin
      this.doApplyFilters()
    },
    displayVIN(vin) {
      if (vin.length === 0) {
        return '—'
      }
      return vin ?? '—'
    },
    loadAllUsers() {
      const req = axiosIns({
        method: 'GET',
        url: '/users/short-list?without=prog,admin',
      })

      req.then(response => {
        const defaultUsers = [{ id: null, full_name: ' ' }]
        defaultUsers.push(...response.data.data)
        this.allUsers = defaultUsers
      })

      return req
    },
    doLoadUsers() {
      return axiosIns({
        method: 'GET',
        url: '/orders',
        params: _.merge(this.filters),
      }).then(response => {
        const responseData = response.data.data
        this.$set(this, 'settings', settingsObjectConverter(responseData))
        this.$set(this, 'rows', rowsObjectConverter(responseData))
      })
    },
    objectDelete(id) {
      this.$swal({
        title: '',
        text: 'Вы уверены что хотите удалить эту запись?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axiosIns({
            method: 'DELETE',
            url: `/orders/${id}`,
          }).then(() => {
            this.doLoadUsers()
            this.$swal({
              icon: 'success',
              title: 'Удалено!',
              text: '',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        }
      })
    },
    formateDate(date) {
      if (!date) {
        return '—'
      }
      const datetime = new Date(date.replace(' ', 'T'))
      const hh = String(datetime.getHours()).padStart(2, '0')
      const ii = String(datetime.getMinutes()).padStart(2, '0')
      const dd = String(datetime.getDate()).padStart(2, '0')
      const mm = String(datetime.getMonth() + 1).padStart(2, '0')
      const yyyy = datetime.getFullYear()
      return `${dd}.${mm}.${yyyy} ${hh}:${ii}`
    },
  },
}
</script>
