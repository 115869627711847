export const settingsObjectConverter = responseData => ({
  currentPage: responseData.current_page,
  total: responseData.total,
  to: responseData.to,
  from: responseData.from,
  perPage: responseData.per_page,
  lastPage: responseData.last_page,
})

export const rowsObjectConverter = responseData => responseData.data

export default { settingsObjectConverter, rowsObjectConverter }
