import EventBus from '@/event-bus'
import store from '@/store'
import Vue from 'vue'
import _ from 'lodash'

export default {
  data() {
    return {
      loading: true,
      columns: [],
      rows: [],
      settings: {},
      filterPromises: [],

      // для фильтров
      filters: {},
      defaultFilters: {
        perPage: 20,
        page: 1,
      },
    }
  },
  methods: {
    loadingPromise(promises) {
      const onlyPromises = []
      this.loading = true

      // Если это функции на обновление фильтров и тп, то запускаем их
      promises.forEach(fn => {
        if (typeof fn === 'function') {
          onlyPromises.push(fn())
        } else {
          onlyPromises.push(fn)
        }
      })

      Promise.all(onlyPromises)
        .then(() => {
          this.loading = false
        })
    },

    // Методы для фильтров, пагинации и другое.
    setDefaultFilter(filterObject, callback) {
      Vue.set(this, 'defaultFilters', _.merge(filterObject, this.defaultFilters))

      if (typeof callback === 'function') {
        callback()
      }
    },
    setFilterToDefault(filterUpdateEvent = false) {
      Vue.set(this, 'filters', _.cloneDeep(this.defaultFilters))
      if (filterUpdateEvent) {
        this.doPushEventFilterUpdated()
      }
    },
    doUpdateFilter(filtersObject) {
      const originalFilters = _.cloneDeep(this.filters)
      Vue.set(this, 'filters', _.merge(originalFilters, filtersObject))
      this.doPushEventFilterUpdated()
    },
    doApplyFilters() {
      const originalFilters = _.cloneDeep(this.filters)
      Vue.set(this, 'filters', _.merge(originalFilters, { page: 1 }))
      this.doPushEventFilterUpdated()
    },
    doResetFilters() {
      this.setFilterToDefault(true)
    },
    doPushEventFilterUpdated() {
      EventBus.$emit('filters:update', true)
    },
  },
  beforeMount() {
    this.setFilterToDefault()
    EventBus.$on('filters:update', () => this.loadingPromise(this.filterPromises))
  },
  beforeDestroy() {
    // Чистим заголовок, если он был поставлен, забываем событие фильтров, если они были
    store.commit('app/UPDATE_CURRENT_PAGE_TITLE', null)
    EventBus.$off('filters:update')
  },
}
